import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layouts/Blog/BlogPost/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`People talks a lot about work-life balance like it is a standard.`}</p>
    <blockquote>
      <p parentName="blockquote">{`“You should work xx hours and then stay with family…”
“Family is more important…”
“Don’t overwork…”`}</p>
    </blockquote>
    <p>{`You should adapt and be flexible. If you will need to work more for your business and you want to do it, don’t beat yourself up because you are not spending enough time with your family and children.`}</p>
    <blockquote>
      <p parentName="blockquote">{`You should work xx hours and then stay with family…`}</p>
    </blockquote>
    <p><img parentName="p" {...{
        "src": "https://miro.medium.com/max/500/1*kcsZSVx6YOTyrZu3WkNXkA.jpeg",
        "alt": "Alt text of image"
      }}></img></p>
    <p>{`Not saying that family is not important. It is really important to stay with your family and see your children grow.
Be flexible with your mindset: your business is important, your family is important, your friendships are important.
Sometimes, you will prioritize work; another time the family.`}</p>
    <p>{`Be flexible and change every day how much time you spend at work based on how life moves on.`}</p>
    <p>{`You are in control of your time: don’t beat yourself up.`}</p>
    <p>{`Be self-aware about yourself.`}</p>
    <h4>{`AUTHOR`}</h4>
    <p>{`Domenico is a software developer with a passion for design, psychology, and leadership.
If you need help with software development, you need consultancy for your technical challenges or you need a leader for your software team or just want to collaborate online, feel free to contact me!`}</p>
    <h4>{`FOLLOW ME`}</h4>
    <p>{`Do you know that I have a YouTube channel? `}<a parentName="p" {...{
        "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
      }}>{`Subscribe!`}</a></p>
    <p>{`Where can you find me?`}</p>
    <p>{`Youtube: `}<a parentName="p" {...{
        "href": "http://bit.ly/YT_DOMENICOSOLAZZO"
      }}>{`Domenico Solazzo's Channel`}</a></p>
    <p>{`Instagram: `}<a parentName="p" {...{
        "href": "https://www.instagram.com/domenicosolazzo/"
      }}>{`domenicosolazzo`}</a></p>
    <p>{`Linkedin: `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/solazzo/"
      }}>{`solazzo`}</a></p>
    <p>{`Medium: `}<a parentName="p" {...{
        "href": "https://medium.com/@domenicosolazzo"
      }}>{`domenicosolazzo`}</a></p>
    <p>{`Facebook: `}<a parentName="p" {...{
        "href": "https://www.facebook.com/domenicosolazzo.labs/"
      }}>{`domenicosolazzo`}</a></p>
    <p>{`Twitter: `}<a parentName="p" {...{
        "href": "https://twitter.com/domenicosolazzo"
      }}>{`domenicosolazzo`}</a></p>
    <p>{`Snapchat: `}<a parentName="p" {...{
        "href": "https://twitter.com/domenicosolazzo"
      }}>{`domenicosolazzo`}</a></p>
    <p>{`Github: `}<a parentName="p" {...{
        "href": "https://github.com/domenicosolazzo"
      }}>{`domenicosolazzo`}</a></p>
    <p>{`Website: `}<a parentName="p" {...{
        "href": "https://www.domenicosolazzo.com"
      }}>{`https://www.domenicosolazzo.com`}</a></p>
    <p><strong parentName="p">{`Hashnode`}</strong>{`: `}<a parentName="p" {...{
        "href": "https://hashnode.com/@domenicosolazzo"
      }}>{`https://hashnode.com/@domenicosolazzo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      